import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Quiz from './states/quiz/Quiz';
import Home from './states/home/Home';
import {BrowserRouter, Outlet, Route, useNavigate} from 'react-router-dom';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import HolisticHealthSeeker from './states/type/HolisticHealthSeeker';
import KnowledgeSeeker from './states/type/KnowledgeSeeker';
import StressProneSeeker from './states/type/StressProneSeeker';
import SeekerTypeMain from './states/type/SeekerTypeMain';
import {Auth0Provider} from '@auth0/auth0-react';
import Profile from './states/profile/Profile';
import {Privacy} from './Privacy';
import ComingSoon from './ComingSoon';


// This redirects to the right route after an auth0 login.
const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
      <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
        {children}
      </Auth0Provider>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element:
        <Auth0ProviderWithRedirectCallback
            domain="dev-ffld3400rslvg3a0.us.auth0.com"
            clientId="C1HqiMIxGGyNtgCnVPbnGWvj5ftTqlPb"
            authorizationParams={{
              redirect_uri: window.location.origin,
            }}>
          <App/>
        </Auth0ProviderWithRedirectCallback>,
    children: [
      {
        path: '/',
        element: <ComingSoon/>,
      },
      {
        path: '/privacy',
        element: <Privacy/>,
      },
      {
        path: '/quiz',
        element: <Quiz/>,
      },
      {
        path: '/profile',
        element: <Profile/>,
      },
      {
        path: '/type',
        element: <SeekerTypeMain/>,
        children: [
          {
            path: '/type/holistic-health-seeker',
            element: <HolisticHealthSeeker/>,
          },
          {
            path: '/type/knowledge-seeker',
            element: <KnowledgeSeeker/>,
          },
          {
            path: '/type/stress-prone-seeker',
            element: <StressProneSeeker/>,
          },
        ],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
      <RouterProvider router={router}/>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
