import {Model} from 'survey-core';
import {Survey} from 'survey-react-ui';
import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import 'survey-core/defaultV2.min.css';
import QuizTheme from './QuizTheme';

function Quiz() {

  const navigate = useNavigate();
  const surveyJson = {
    pages: [
      {
        elements: [
          {
            name: 'CurrentSituation',
            title: 'To tailor your experience, let us know your current situation:',
            type: 'radiogroup',
            choices: [
              {value: 'CurrentlyPregnant', text: 'Currently pregnant'},
              {value: 'TryingToConceive', text: 'Trying to conceive'},
              {value: 'NewMom', text: 'A new mom'},
              {
                value: 'NotTrying',
                text: 'Not trying, but would like to have a baby in the future',
              },
            ],
            isRequired: false,
          }],
      },
      {
        elements: [
          {
            name: 'HowLongHaveYouBeenTrying',
            title: 'How many months have you been trying to conceive?',
            type: 'text',
            inputType: 'number',
            min: 0,
            max: 100,
            defaultValue: 6,
            isRequired: true,
          }],
      },
      {
        elements: [
          {
            name: 'WhichAspectsAreYouInterestedIn',
            title: 'Which aspects of preconception wellness are you interested in?',
            type: 'checkbox',
            choices: [
              'Diet and nutrition',
              'Physical activity and exercise',
              'Mental Health',
              'Skincare',
              'Eastern fertility treatments',
              'Genetic counseling and testing',
              'Finding a community',
            ],
            isRequired: false,
            showOtherItem: true,
          }],
      },
      {
        elements: [
          {
            name: 'Age',
            title: 'Age Range',
            type: 'radiogroup',
            choices: [
              {value: '18to24', text: '18-24'},
              {value: '25to29', text: '25-29'},
              {value: '30to34', text: '30-34'},
              {value: '35to39', text: '35-39'},
              {value: '40to42', text: '40-42'},
              {value: '43plus', text: '43+'},
            ],
            isRequired: false,
          }],
      },
      {
        elements: [
          {
            name: 'Location',
            title: 'Location (city, state)',
            type: 'text',
          }],
      }, {
        elements: [
          {
            name: 'RelationshipStatus',
            title: 'What is your relationship status?',
            type: 'radiogroup',
            choices: [
              {value: 'Single', text: 'Single'},
              {value: 'Married', text: 'Married'},
              {value: 'InARelationship', text: 'In a relationship'},
            ],
            isRequired: false,
          }],
      }, {
        elements: [
          {
            name: 'PregnantBefore',
            title: 'Have you ever been pregnant before?',
            type: 'radiogroup',
            choices: [
              {value: true, text: 'Yes'},
              {value: false, text: 'No'},
            ],
            isRequired: false,
          }],
      }, {
        elements: [
          {
            name: 'TimesPregnantBefore',
            title: 'How many times have you been pregnant before?',
            type: 'radiogroup',
            choices: [
              {value: 'Once', text: 'Once'},
              {value: '1to3', text: '1-3 times'},
              {value: 'MoreThan3', text: 'More than 3 times'},
            ],
            visibleIf: '{PregnantBefore}',
            isRequired: false,
          }],
      }, {
        elements: [
          {
            name: 'ConsideredFertilityOptions',
            title: 'Have you consider or pursued any of the following fertility options?',
            type: 'checkbox',
            choices: [
              {value: 'EggFreezing', text: 'Egg freezing'},
              {value: 'EmbryoFreezing', text: 'Embryo freezing'},
              {value: 'IVF', text: 'IVF'},
              {value: 'IUI', text: 'IUI'},
              {value: 'DonorEggs', text: 'Donor eggs'},
              {value: 'DonorEmbryos', text: 'Donor embryos'},
              {value: 'Surrogacy', text: 'Surrogacy'},
            ],
            visibleIf: '{PregnantBefore}',
            showNoneItem: true,
            showOtherItem: true,
            isRequired: false,
          }],
      }, {
        elements: [
          {
            name: 'FertilityRelatedHealthIssues',
            title: 'Have you experienced any fertility-related health issues?',
            type: 'checkbox',
            choices: [
              'Lack of regular ovulation/periods',
              'Diminished ovarian reserve (low egg count)',
              'Blocked or damaged fallopian tubes',
              'Endometriosis/PCOS',
            ],
            showNoneItem: true,
            showOtherItem: true,
          }],
      }, {
        elements: [
          {
            name: 'ExperiencedComplications',
            title: 'Have you experienced any of the following pregnancy complications?',
            type: 'checkbox',
            choices: [
              'High blood pressure',
              'Gestational diabetes',
              'Preeclampsia',
              'Preterm labor',
              'Premature birth',
              'Depression and anxiety',
              'Pregnancy loss/miscarriage',
              'Stillbirth',
              'Placenta previa',
            ],
            showNoneItem: true,
            showOtherItem: true,
          }],
      }, {
        elements: [
          {
            name: 'TriedEasternHealthPractices',
            title: 'Have you tried any of the following Eastern health practices?',
            type: 'checkbox',
            choices: [
              'Acupuncture',
              'Cupping',
              'Moxicombustion',
              'Asian herbs/teas',
              'No, but open to more information',
            ],
            showOtherItem: true,
          }],
      }, {
        elements: [
          {
            name: 'UsingBirthControl',
            title: 'Are you using any form of birth control?',
            type: 'radiogroup',
            choices: [
              'Yes',
              'No',
            ],
          }],
      }, {
        elements: [
          {
            name: 'Diet',
            title: 'Which best describes your diet?',
            type: 'radiogroup',
            choices: [
              'Vegetarian',
              'Vegan',
              'Omnivore',
              'Pescatarian',
            ],
            showOtherItem: true,
          }],
      }, {
        elements: [
          {
            name: 'TakingPrenatalVitamins',
            title: 'Are you currently taking any prenatal vitamins?',
            type: 'radiogroup',
            choices: [
              {value: true, text: 'Yes'},
              {value: false, text: 'No'},
            ],
          }],
      }, {
        elements: [
          {
            name: 'PrenatalVitaminTypes',
            title: 'Please specify which prenatal vitamins or supplements you are currently taking',
            type: 'text',
            visibleIf: '{TakingPrenatalVitamins}',
          }],
      }, {
        elements: [
          {
            name: 'TakingOtherVitamins',
            title: 'Are you taking any other vitamins or supplements?',
            type: 'radiogroup',
            choices: [
              {value: true, text: 'Yes'},
              {value: false, text: 'No'},
            ],
          }],
      }, {
        elements: [
          {
            name: 'OtherVitaminTypes',
            title: 'Please specify which other vitamins or supplements you are currently taking',
            type: 'text',
            visibleIf: '{TakingOtherVitamins}',
          }],
      }, {
        elements: [
          {
            name: 'TakenAntibiotics',
            title: 'Have you taken antibiotic medication in the last year?',
            type: 'radiogroup',
            choices: [
              {value: 'Once', text: 'Once'},
              {value: '1to3', text: '1-3 times'},
              {value: 'MoreThan3', text: 'More than 3 times'},
              {value: 'No', text: 'No'},
            ],
            isRequired: false,
          }],
      }, {
        elements: [
          {
            name: 'AlcoholicBeverages',
            title: 'Do you have one or more alcoholic beverages a day?',
            type: 'radiogroup',
            choices: [
              'Yes',
              'Occasionally',
              'No',
            ],
            isRequired: false,
          }],
      }, {
        elements: [
          {
            name: 'Smoke',
            title: 'Do you smoke?',
            type: 'radiogroup',
            choices: [
              {value: true, text: 'Yes'},
              {value: false, text: 'No'},
            ],
          }],
      }, {
        elements: [
          {
            name: 'PhysicalActivity',
            title: 'How often do you engage in physical activity or regular movement?',
            type: 'radiogroup',
            choices: [
              'Daily',
              'A few times a week',
              'Once a week',
              'A few times a month',
              'Rarely',
            ],
            isRequired: false,
          }],
      }, {
        elements: [
          {
            name: 'TypesOfPhysicalActivity',
            title: 'What type of physical activity do you engage in?',
            type: 'checkbox',
            choices: [
              'Walking',
              'Running',
              'Cycling',
              'Strength training',
              'Swimming',
              'Yoga',
              'Pilates',
              'Stretching',
            ],
            isRequired: false,
            showOtherItem: true,
            showNoneItem: true,
          }],
      }, {
        elements: [
          {
            name: 'MorePhysicalActivity',
            title: 'Would you like to incorporate more physical activity into your routine?',
            type: 'radiogroup',
            choices: [
              {value: true, text: 'Yes'},
              {value: false, text: 'No'},
            ],
          }],
      }, {
        elements: [
          {
            name: 'SleepQuality',
            title: 'How would you rate the quality of your sleep overall?',
            type: 'radiogroup',
            choices: [
              'Excellent',
              'Good',
              'Fair',
              'Poor',
              'Very poor',
            ],
            isRequired: false,
          }],
      }, {
        elements: [
          {
            name: 'HoursOfSleep',
            title: 'How many hours of sleep do you get per day?',
            type: 'radiogroup',
            choices: [
              '0-3',
              '4-5',
              '6-7',
              '7-8',
              '9+',
            ],
            isRequired: false,
          }],
      },
    ],
  };

  const customCss = {
    'question': {
      'content': 'question-content',
      'answered': 'question-answered',
      'titleRequired': 'question-title-required',
    },
  };

  const surveyComplete = useCallback((survey) => {
    navigate('/type/holistic-health-seeker');
  }, []);

  const survey = new Model(surveyJson);
  survey.applyTheme(QuizTheme);
  survey.onComplete.add(surveyComplete);

  return (
      <Survey model={survey}/>
  );
}

export default Quiz;