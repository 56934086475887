function HolisticHealthSeeker() {
  return (
      <div className={'PersonaType'}>

        <div style={{margin: 20}}>
          Your Wellness Persona:
        </div>

        <div>
          <img style={{width: 200, height: 200}}
               src={require('../../images/quiz_flower_transparent.png')}/>
        </div>

        <h2>The Holistic Health Seeker</h2>

        This woman embraces a holistic approach to fertility, blending Western
        and Eastern medicine traditions. She focuses on balancing her body,
        mind, and spirit to optimize fertility. She prioritizes a nourishing
        diet, regular exercise, and emotional well-being.
        <br/>

        <div className={"LearnMoreButton"}>
          Learn More
        </div>

      </div>

  );
}

export default HolisticHealthSeeker;