export const Privacy = () => {
  return (<div>

    <p><h2>PRIVACY POLICY</h2></p>
    <p>
      <span>Kenko Space LLC (&ldquo;</span><b>Kenko</b><span>&rdquo;, &ldquo;</span><b>we</b><span>&rdquo;, or &ldquo;</span><b>us</b><span>&rdquo;) is committed to respecting your privacy. This Privacy Policy will help you better understand how we collect, use, and share your personal information when you use our website (</span><b>www.kenko.space</b><span>, our &ldquo;</span><b>Site</b><span>&rdquo;), the Kenko application (our &ldquo;</span><b>App</b><span>&rdquo;), and related products or services (together, our &ldquo;</span><b>Services</b><span>&rdquo;).</span>
    </p>
    <h2><span>WHAT PERSONAL INFORMATION WE COLLECT</span></h2>
    <p>
      <span>The information we collect depends on how you interact with us, the services you use, and the choices you make. We collect information about you from different sources and in various ways when you use our Services, including information you provide directly, information collected automatically, information from third-party data sources, and data we infer or generate from other data. </span>
    </p>
    <p><span>When you sign up for or use Kenko, you share certain information, such as: </span>
    </p>
    <ul>
      <li><b>Personal information and identifiers:</b><span>&nbsp;We collect name, username or alias, and contact details such as email address, postal address, and phone number. </span>
      </li>

      <li><b>Demographic data:</b><span>&nbsp;In some cases, we request that you provide or you may offer age, gender, marital status, and similar demographic details. </span>
      </li>

      <li><b>Payment information:</b><span>&nbsp;If you make a purchase or other financial transaction, we collect credit card numbers, financial account information, and other payment details. </span>
      </li>

      <li><b>Contents and files: </b><span>We collect the photos, documents, or other files you upload to Kenko and if you send us email messages or communications, we collect and retain those communications. </span>
      </li>

      <li><b>Sensitive Personal Information: </b>
      </li>
      <ul>
        <li><span style={{textDecoration: 'underline'}}>Account access information</span><span>: </span><span>We collect information such as a username or account number in combination with a password, security or access code, or other credential that allows access to an account.</span><span>&nbsp;</span>
        </li>
      </ul>

      <ul>
        <li><span style={{textDecoration: 'underline'}}>Contents of communications</span><span>: We collect the contents of messages you send in chats and message boards in our apps. </span>
        </li>
      </ul>

      <ul>
        <li><span style={{textDecoration: 'underline'}}>Health data</span><span>: We collect and analyze information concerning your health, such as your wellness background, weight, height, lifestyle, medical history, medical condition, genetic information, healthcare providers, information about your health and/or disability status, including pregnancy information. </span>
        </li>
      </ul>

      <ul>
        <li><span style={{textDecoration: 'underline'}}>Sensitive demographic data</span><span>: We collect information such as age, date of birth, sex, gender, national origin, citizenship, race, marital status, sexual orientation, medical condition, genetic information, and information about your health and/or disability status, including pregnancy information. </span>
        </li>
      </ul>


      <li>
        <b>Technical information collected automatically when you use
          Kenko: </b>
        <span>When you use our website or mobile application, certain internet and electronic network activity information gets created and logged automatically. Here are some of the types of information we collect: </span>
        <ul>
          <li><span style={{textDecoration: 'underline'}}>Log data</span><span>: When you use Kenko, our servers record information (&ldquo;</span><span>log data</span><span>&rdquo;), including information that your browser automatically sends whenever you visit a website, or that your mobile app automatically sends when you&rsquo;re using it. This log data includes your IP address, browser type and settings, and the date and time you used Kenko. </span>
          </li>

          <li><span
              style={{textDecoration: 'underline'}}>Geolocation data</span><span>: Depending on your device and app settings, we collect geolocation data when you use our apps or online services. For example, we may infer your general geographic location (such as city, state, and country) based on your IP address. </span>
          </li>

          <li><span
              style={{textDecoration: 'underline'}}>Cookie data</span><span>: We and our partners also use cookies, web beacons, mobile analytics and advertising device IDs, and similar technologies. We and our partners use these technologies in websites, apps, and online services to collect personal data (such as the pages you visit, the links you click on, and similar usage information, identifiers, and device information) when you use our services, including personal data about your online activities over time and across different websites, apps, or online services. For more information on our use of cookies, please see our </span><span>Cookie Policy</span><span>. </span>
          </li>

          <li><span
              style={{textDecoration: 'underline'}}>Device information</span><span>: In addition to log data, we collect information about the device you&rsquo;re using Kenko on, including the type of device, operating system, settings, unique device identifiers, and crash data. </span>
          </li>

          <li><span style={{textDecoration: 'underline'}}>Usage data and customization</span><span>: When you&rsquo;re on Kenko, we use your activity&mdash;such as your period dates&mdash; to customize your experience. We also automatically log your other activity on our Site, App, and connected products, including the URL of the website from which you came to our Site, pages you viewed, how long you spent on a page, access times, and other details about your use of and actions on our website. </span>
          </li>

          <li><span
              style={{textDecoration: 'underline'}}>Sensor data</span><span>: We may also receive data from third party sensors you choose to connect; for example, you can choose to connect your </span><span>Apple</span><span>&nbsp;Watch Health App with Kenko so we can receive and help you track your step and exercise data. </span>
          </li>
        </ul>

      </li>


      <li><b>Information we create or generate:</b><span>&nbsp;We infer new information from other data we collect, including using automated means to generate information about your likely preferences or other characteristics (&ldquo;</span><span>inferences</span><span>&rdquo;). </span>
      </li>

      <li>
        <b>Information collected from third-party
          services</b><span>: </span><span>We also obtain the types of information described above from third parties. These third-party sources include, for example: </span>

        <ul>
          <li><span
              style={{textDecoration: 'underline'}}>Third-party partners</span><span>: Third-party applications and services, including social networks you choose to interact with to connect to our services. What we have access to is dependent on the privacy policies or settings for those accounts. </span>
          </li>

          <li><span style={{textDecoration: 'underline'}}>Information our advertisers share with us</span><span>: We also get information about you and your activity from our advertising partners and other third parties we work with. For example, online advertisers or third parties share information with us to measure, report on, or improve the performance of ads for Kenko. </span>
          </li>

          <li><span style={{textDecoration: 'underline'}}>Co-branding/marketing partners</span><span>: Partners with which we offer co-branded services or engage in joint marketing activities. </span>
          </li>

          <li><span
              style={{textDecoration: 'underline'}}>Service providers</span><span>: Third parties that collect or provide data in connection with work they do on our behalf. For example, companies that determine your device&rsquo;s location based on its IP address. </span>
          </li>
        </ul>
      </li>
    </ul>

    <p><span>When you are asked to provide your information, you may decline or use browser or device controls to prevent certain types of data collection. In some cases, if you choose not to provide information that is necessary, some services or features may not be available or fully functional. </span>
    </p><h2 id="h.egx0alp1ayo2">
    <span>HOW WE USE YOUR PERSONAL INFORMATION</span>
  </h2><p><span>We may use your personal information for the following purposes, depending on your relationship with Kenko and how you use our Services:</span>
  </p>
    <ul>
      <li><b>Provide our Services. </b><span>To provide and deliver our Services, connect you with </span><span>wellness professionals offering their services via the Service </span><span>(&ldquo;</span><span>Consultants</span><span>&rdquo;), refer you to partners, process payments, and respond to your questions and comments.</span>
      </li>
    </ul>
    <ul>
      <li><b>Business operations. </b><span>To operate our business, such as billing, accounting, improving our internal operations, securing our systems, detecting fraudulent or illegal activity, and meeting our legal obligations.</span>
      </li>
      <li><b>Personalization. </b><span>To understand you and your preferences to enhance your experience and enjoyment using our Services.</span>
      </li>
      <li><b>Customer support.</b><span>&nbsp;To provide customer support and respond to your feedback, questions, and comments. </span>
      </li>
      <li><b>Communications. </b><span>To send you information, including educational materials, confirmations, invoices, technical notices, updates, security alerts, reminders, support, and administrative messages.</span>
      </li>
      <li><b>Marketing. </b><span>To communicate with you about new services, offers, promotions, rewards, contests, upcoming events, and other information about our Services and those of our selected partners.</span>
      </li>
      <li>
        <b>Advertising. </b><span>To display advertising to you.</span>
      </li>
      <li><b>Improve our Services.</b><span>&nbsp;To improve our Services, perform market research and analysis, assess how our Services are being used, and develop new Services.</span>
      </li>
      <li><b>Secure our Services and systems. </b><span>To maintain the security and integrity of our Services, detect potential security vulnerabilities and incidents, protect our Services against fraudulent and illegal activity, and enforce our </span><span>Terms of Use</span><span>.</span>
      </li>
      <li><b>For research and development. </b><span>We may de-identify, pseudonymize, or anonymize your personal information by removing certain identifiable data points (such as your name, email address, or linkable tracking ID) that makes the data personally identifiable to you or through other means. We do this so that, where permitted by law, we can conduct research and develop our products and Services.</span>
      </li>
      <li><b>Comply with legal obligations. </b><span>To comply with our legal obligations, including maintaining records of our compliance with international, federal, and state law requirements, and responding to court orders or subpoenas. Absent exigent circumstances, we will only provide your personal information in response to a third party subpoena if it is issued by a court with jurisdiction to compel us to produce your personal information, and we believe we are legally bound to do so. We may also use your personal information to establish, exercise, or defend legal claims.</span>
      </li>
    </ul>
    <p><span>We may also use your personal information for other purposes consistent with the context of the collection of your personal information, for internal business purposes (for example when evaluating investments or investors), for additional purposes disclosed to you at the time your personal information is collected, or with your consent.</span>
    </p><h2 id="h.zek7h6yo78yo">
    <span>HOW AND WHEN WE SHARE YOUR INFORMATION</span>
  </h2><p>
    <span>We may share your information with, for example: </span>
  </p>
    <ul>
      <li><b>Service providers: </b><span>We may share your personal information with service providers that we use to help us operate our business, including: maintaining, operating, hosting, and improving our Services; payment processing; communicating with customers or business partners; data analytics; and marketing and advertising. We try to limit our service providers&rsquo; use of your information to that which may be necessary to perform their services for us.</span>
      </li>

      <li>
        <b>Financial services and payment processing:</b><span>&nbsp;When you provide payment data, for example to make a purchase, we will disclose payment and transactional data to banks and other entities as necessary for payment processing, fraud prevention, credit risk reduction, analytics, or other related financial services. </span>
      </li>

      <li><b>Other services:</b><span>&nbsp;Other services, at your direction, when you decide to link your Kenko account to those services. If you link your Kenko account to any of those third parties, or allow us to share your information with them, that data is governed by their privacy policies. </span>
      </li>

      <li><b>Consultants: </b><span>When you choose to engage with Consultants, we may share your personal information with them so that they may provide services to you.</span>
      </li>

      <li><b>Corporate transactions: </b><span>We may disclose your information as part of a corporate transaction or proceeding such as a merger, financing, acquisition, bankruptcy, dissolution, or a transfer, divestiture, or sale of all or a portion of our business or assets. </span>
      </li>

      <li><b>Legal and law enforcement: </b><span>We will access, disclose, and preserve your information when we believe that doing so is necessary to comply with applicable law or respond to valid legal process, including from law enforcement or other government agencies. </span>
      </li>

      <li><b>Safety, security, and protecting rights:</b><span>&nbsp;We will also disclose your information if we believe it is necessary to: </span>
        <ul>
          <li><span>Protect our customers and others</span><span>; for example, to prevent spam or attempts to commit fraud, or to help prevent the loss of life or serious injury of anyone.</span>
          </li>

          <li>
            <span>Operate and maintain the security of our services</span><span>, including to prevent or stop an attack on our computer systems or networks. </span>
          </li>

          <li>
            <span>Protect the rights or property of ourselves or others</span><span>, including enforcing our agreements, terms, and policies. </span>
          </li>
        </ul>
      </li>


      <li><b>Advertising providers:</b><span>&nbsp;Third party analytics and advertising companies also collect personal data through our Site and App, including identifiers and device information (such as cookie IDs, device IDs, and IP address), geolocation data, log data, and inferences based on and associated with that data, as described in our Cookie Policy. These third-parties may combine this data across multiple sites to improve analytics for their own purposes and for others. </span>
      </li>
    </ul>
    <p><span>Some of the information disclosures to these third parties may be considered a &ldquo;sale&rdquo; or &ldquo;sharing&rdquo; of personal information as defined under the laws of California and other U.S. states. Please see &ldquo;Your rights and choices&rdquo; and &ldquo;State-specific disclosures&rdquo; below for more details. </span>
    </p><h2 id="h.tsq4v73yqpxv"><span>YOUR RIGHTS AND CHOICES</span>
  </h2><p>
    <span>We believe you should be able to access and control your personal information no matter where you live. Depending on how you use our Services, you may have the right to request access to, correct, amend, delete, port to another service provider, restrict, or object to certain uses of your personal information. We will not charge you more or provide you with a different level of service if you exercise any of these rights. Please note that a number of these rights apply only in certain circumstances, and all of these rights may be limited by law or our legal obligations to others. Additional information about some of these rights is included in &ldquo;State-specific disclosures&rdquo; below. </span>
  </p><p><span>If you wish to submit a request, please contact us using the contact information below. If you are not happy with our response to a request, you can contact us to resolve the issue. </span>
  </p><p>
    <span>Finally, because there is no common understanding about what a &ldquo;Do Not Track&rdquo; signal is supposed to mean, we don&rsquo;t respond to those signals in any particular way.</span>
  </p><h2 id="h.1pva8ffgkkg6">
    <span>WHERE DO WE SEND YOUR PERSONAL INFORMATION?</span><span>&nbsp;</span>
  </h2><p><span>To operate our business, unless otherwise restricted or prohibited, we may send your personal information outside of your country, province, or state. This information may be subject to the laws of the countries where we send it. We take steps to protect your information when we send your information across borders.</span>
  </p><h2 id="h.k9n9rmvu439s">
    <span>HOW DO WE PROTECT YOUR PERSONAL INFORMATION? </span>
  </h2><p>
    <span>We implement and maintain appropriate and reasonable technical, physical, administrative, managerial, and organizational security measures designed to safeguard your personal information. Please note, however, we cannot fully eliminate security risks associated with the storage and transmission of personal information. You also must keep your password secure and your account confidential. If you have reason to believe that the security of your account has been compromised, please notify us immediately at </span><span>support@kenko.space</span><span>.</span>
  </p><h2 id="h.7x85mz2xbt7f"><span>UNSUBSCRIBING FROM MARKETING </span>
  </h2><p>
    <span>If you would like to stop receiving newsletters or other marketing or promotional messages, notifications, or updates, you may do so by following the unsubscribe instructions that appear in these e-mail communications. Please be advised that you may not be able to opt-out of receiving certain service or transactional messages from us, including legal notices.</span>
  </p><h2 id="h.au9n51yegj54"><span>CHILDREN&rsquo;S PRIVACY</span></h2><p>
  </p><p><span>Our service is not directed at minors. If you are considered a minor under the privacy laws in your country, you cannot use our services without verifiable written consent from your parent or guardian.</span>
  </p><h2 id="h.gmq9glg68yi7"><span>LINKS TO OTHER SITES</span>
  </h2><p>
    <span>Our Services may link to content from another website, application, or service. Please note that we have no control over and are not responsible for these external sites, their content, or any goods or services available through them. This Privacy Policy does not apply to those external sites, and we encourage you to review their independent privacy statements.</span>
  </p><h2 id="h.xkd9bxryamkh"><span>HOW LONG DO WE KEEP YOUR INFORMATION?</span>
  </h2><p>
    <span>We keep your information only so long as we need it to provide our services to you, fulfill the purposes described in this policy, comply with our legal obligations, resolve disputes, and enforce our agreements. To determine the appropriate retention period, we consider the amount, nature and sensitivity of your personal data, the nature of our relationship with you, the potential risk of harm from unauthorized use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and any applicable legal requirements. Actual retention periods can vary significantly based on your expectations and consent, the sensitivity of the data, the availability of automated controls, and our legal or contractual obligations. </span>
  </p><h2 id="h.x7hmfeqpl1l3"><span>STATE-SPECIFIC DISCLOSURES</span>
  </h2><p><span>If you are a customer covered by &ldquo;Washington&rsquo;s My Health, my Data&rdquo; Act or Nevada&rsquo;s SB 370, please visit our Consumer Health Data Privacy Notice</span><span>. If you are a resident of California, Virginia, Utah, Colorado, or Connecticut, you have certain rights and choices you should know about: </span>
  </p>
    <ul>
      <li><b>Notice at Collection:</b><span>&nbsp;You have a right to receive notice of our practices before or when we collect your information, including the categories of information and sensitive personal information collected, the purposes for which such information is collected or used, whether such information is sold or shared, and how long such information is retained. You can find those details in this Privacy Policy under the headers above. </span>
      </li>
      <li><b>Right to Know: </b><span>You have a right to see the information we have collected about you. You may request your information by contacting us at </span><span>support@kenko.space</span><span>&nbsp;using the email address tied to your Kenko account. You also have a right to request additional information about our collection, use, disclosure, selling or sharing of such information. You can find those details in this &nbsp;Privacy Policy under the headers above. </span>
      </li>
      <li><b>Rights to Request Correction or Deletion: </b><span>You also have rights to request that we correct inaccurate information about you or delete your information. </span><span>You can log into your profile to update your information</span><span>, or delete your data at any time by logging into your account or by contacting us at </span><span>support@kenko.space</span><span>&nbsp;using the email address tied to your Kenko account. Please note that there may be legal reasons for us to keep your data, such as if we receive a law enforcement request asking us to preserve data.</span><span></span>
      </li>
      <li><b>Right to Opt-Out/Right to Limit Use and Disclosure of Sensitive
        Personal
        Information: </b><span>You have a right to opt-out from the &ldquo;sale&rdquo; or &ldquo;sharing&rdquo; of your personal information for targeted advertising. </span>
      </li>
      <li><b>Right Against Discrimination: </b><span>Kenko will never discriminate against you for exercising these rights. You may designate, in writing or through a power of attorney, an authorized agent to exercise these rights on your behalf. Before accepting such a request from an agent, we will require the agent to provide proof you have authorized them to act on your behalf, and we may need you to verify your identity directly with us. </span>
      </li>
    </ul>
    <p><span>Lastly, under California Civil Code section 1798.83, also known as the &ldquo;Shine the Light&rdquo; law, California residents who have provided personal information to a business with which they have established a business relationship for personal, family, or household purposes (&ldquo;</span><b>California
      Customers</b><span>&rdquo;) may request information about whether the business has disclosed personal information to any third parties for the third parties&rsquo; direct marketing purposes.</span>
    </p><h2 id="h.h5fbnwe2c7mp"><span>CHANGES TO THIS PRIVACY POLICY</span>
  </h2><p>
    <span>We may change this Privacy Policy from time to time and if we do, we&rsquo;ll post any changes on this page. We encourage you to review this Privacy Policy often to stay informed of how we may process your information. If you continue to use Kenko after those changes are in effect, you agree to the new policy. If we have the ability to do so (e.g., if you have an active account, or we have contact information for you), we will attempt to notify you of any material changes to our Privacy Policy that impact your personal information.</span>
  </p><h2 id="h.24vtrijnryv8"><span>CONTACT US</span></h2>
    <p>
      <span>The best way to get in touch with us is by emailing </span><span><a
        href="mailto:info@kenko.space">info@kenko.space</a></span><span>.</span>
    </p><p><span>Updated May 30, 2024</span>
  </p>
  </div>);
};
   