import EmailSignup from './EmailSignup';

function ComingSoon() {
  return (
      <div className={"ComingSoon"}>
        
        <div className={"ComingSoonBanner"}>KENKO</div>
        
        <h2>A Guide to Preconception Wellness</h2>
        <h3>Coming Soon</h3>
        <EmailSignup/>
      </div>
      
  )
}

export default ComingSoon;