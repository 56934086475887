function EmailSignup() {
  return (
      <form
          action="https://space.us18.list-manage.com/subscribe/post?u=80130a0f5b7b291db00f21d6c&amp;id=3d58af18a7&amp;f_id=00a93be1f0"
          method="post" id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form" className="validate"
          target="_blank">
        <div className="ComingSoonEmailBox">
          <h4>Get Exclusive First Access</h4>
          <input type="email" name="EMAIL" className="ComingSoonEmailLabel"
                 id="mce-EMAIL" required="true" placeholder="Email Address"
          />
          <div aria-hidden="true"
               style={{position: 'absolute', left: '-5000px'}}>
            /* real people should not fill this in and expect good things - do
            not remove this or risk form bot signups */
            <input type="text" name="b_80130a0f5b7b291db00f21d6c_3d58af18a7"
                   tabIndex="-1" value=""/>
          </div>
          <input type="submit" name="subscribe" id="mc-embedded-subscribe"
                 className="ComingSoonEmailButton" value="Subscribe"/>

        </div>
      </form>
  );
}

export default EmailSignup;