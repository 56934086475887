import React from 'react';
import {useAuth0} from '@auth0/auth0-react';

function Profile() {

  const {user, loginWithRedirect, isAuthenticated, isLoading} = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (isAuthenticated) {
    return (
        <div>
          <h2>My Kenko Profile</h2>
          <img src={user.picture} alt={user.name}/>
          <h3>{user.name}</h3>
          <p>{user.email}</p>
        </div>
    );
  }

  return (
      <div>
        You are logged out. Try logging in:
        <br/>
        <a href={'#'} onClick={() => loginWithRedirect()}>Log In</a>
      </div>
  );
}

export default Profile;