import {Outlet} from 'react-router-dom';

function SeekerTypeMain() {
  return (

      <div>
        <Outlet/>

        <br/>
        <br/>
        <br/>

      </div>

  );
}

export default SeekerTypeMain;

