import {Text} from 'react';

function KnowledgeSeeker() {
  return (
      <div>
        <h2>The Knowledge Seeker</h2>

        <b>Characteristics</b>: This woman proactively educates herself on
        fertility methods and explores complementary therapies. She values
        Eastern medicine and integrates it into her preconception journey.
        <br/>
        <br/>
        <b>Actions</b>:
        <ol>
          <li style={{padding: '5px'}}>

            Incorporate acupuncture sessions into her preconception plan to
            regulate menstrual cycles, improve ovarian function, and enhance
            fertility. She may also explore other TCM modalities such as herbal
            medicine and dietary therapy.
          </li>

          <li style={{padding: '5px'}}>

            Engage in fertility-enhancing exercises such as yoga for
            fertility, which includes poses specifically designed to support
            reproductive health and balance hormones. She may also incorporate
            fertility-friendly exercises like walking, cycling, or swimming.
          </li>

          <li style={{padding: '5px'}}>
            Follow a fertility-boosting diet rich in antioxidants, vitamins,
            minerals, and healthy fats. She includes foods like berries, leafy
            greens, cruciferous vegetables, whole grains, lean proteins, nuts,
            seeds, and omega-3 fatty acids from sources like fatty fish and
            flaxseeds.
          </li>

          <li style={{padding: '5px'}}>
            Hydrate with fertility-supporting beverages like green tea, which
            contains antioxidants that may benefit reproductive health. She may
            also drink plenty of water and herbal teas like red raspberry leaf
            tea, which is believed to tone the uterus and support fertility.
          </li>

          <li style={{padding: '5px'}}>
            Explore additional modalities such as fertility massage,
            reflexology, and aromatherapy to promote relaxation, reduce stress,
            and support reproductive health.
          </li>

        </ol>
      </div>
  );
}

export default KnowledgeSeeker;