import './App.css';
import './Header';
import Header from './Header';
import Footer from './Footer';
import {useState} from 'react';
import {Outlet} from 'react-router-dom';

function App() {
  return (
      <div className="App">
        <div className="AppFlex">

          {/*<ComingSoon/>*/}

          {/*<Header/>*/}

          <Outlet/>

          {/*<Footer/>*/}
        </div>
      </div>
  );
}

export default App;
